import axios from "axios";

import { BASE_URL } from "../env/Baseurl";

const register = (
  user_email,
  user_name,
  password,
  confirm_password,
  user_situation,
  applied_situation,  
  user_age,
  first_name,
  last_name,
  city,
  country,
  countryCode,
  dialCode,
  domain_name,
  lc                  //languageCode
) => {
  const params = JSON.stringify({
    user_name: user_name,
    fname: first_name,
    Lname: last_name,
    email: user_email,
    password: password,
    cpassword: confirm_password,
    role_id: 1,
    city: city,
    country: country,
    my_situation: user_situation,
    my_situation_options: applied_situation,
    age: user_age,
    ageGreater18: true,
    countryCode: countryCode,
    dialCode: dialCode,
    domain_name: domain_name,
    languageCode: lc,                       //languageCode
  });
  console.log(params, "its data of user");

  return axios
    .post(BASE_URL + "signup", params, {
      headers: {
        "content-type": "application/json",
      },
    })
    .then((response) => {
      localStorage.setItem("user", JSON.stringify(response.data));
      localStorage.setItem("userState", "true");
      return response.data;
    });
};

const login = (email, password) => {
  const params = JSON.stringify({
    email: email,
    password: password,
  });

  return axios
    .post(BASE_URL + "signin", params, {
      headers: {
        "content-type": "application/json",
      },
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("userState", "true");
      }
      return response.data;
    });
};

const logout = () => {
  //localStorage.removeItem("languageCode");
  localStorage.removeItem("user");
  localStorage.removeItem("last_visited_url");
};

const resetPassword = (user_email) => {
  const params = JSON.stringify({
    email: user_email,
  });

  return axios
    .post(BASE_URL + "forgetPassword", params, {
      headers: {
        "content-type": "application/json",
      },
    })
    .then((response) => {
      // alert(response.data);
      return response.data;
    });
};

export default {
  register,
  login,
  logout,
  resetPassword,
};
