/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../actions/auth";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { CLEAR_MESSAGE } from "../../../actions/types";
import "../../../styles/login.css";
import { DOMAIN_NAME } from "../../../env/Baseurl";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { language_list } from "../../../constants/languages/languages";
import Select from "react-select";

const LoginScreen = (props) => {
  /*******   States  *******/
  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const { t } = useTranslation(["translation", "login", "validationRegisterPage"])
  const [email, setEmail] = useState({
    value: "",
    hasError: false,
    touched: false,
  });
  const [logineEmptyCheck, setloginEmptyCheck] = useState({
    email: "",
    password: "",
  });
  const [values, setValues] = useState({
    languageCode: "",
    language: "",
  });
  const [emptyCheck, setEmptyCheck] = useState({
    languageCode: "",
  });

  const [language, setLanguage] = useState("");

  const languageHandler = (e) => {
    const selectedLanguage = e.value;
    setLanguage(selectedLanguage);
    setValues({ ...values, languageCode: selectedLanguage, language: e.label });
    i18next.changeLanguage(selectedLanguage);
    localStorage.setItem('languageCode', selectedLanguage);
  };

  const [showLogin, setLoginPage] = useState(false)

  /*******   LocalStorage  *******/
  const userState = localStorage.getItem("userState");
  console.log(userState, "its user state");
  const userLastVisited = localStorage.getItem("last_visited_url");

  /*******   Redux  *******/
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  /*******   HelperFunctions  *******/
  let navigate = useNavigate();

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const changeHandler = (e) => {
    const inputValue = e.target.value.trim().toLowerCase();
    let hasError = false;
    if (
      !/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(
        inputValue
      )
    ) {
      hasError = true;
    }
    setEmail((currentValue) => ({
      ...currentValue,
      value: e.target.value,
      hasError,
    }));
  };

  const blurHandler = () => {
    setEmail((currentValue) => ({
      ...currentValue,
      touched: true,
    }));
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const style = {
    placeHolderStyle: {
      fontSize: "14px",
      color: "#929BA2",
    },
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (email.value === "") {
      setloginEmptyCheck({ email: t('login:fields.email.errorMessages.empty') });
    } else if (password === "") {
      setloginEmptyCheck({
        password: t('login:fields.password.errorMessages.empty'),
      });
    } else if (
      email.value !== "" &&
      password !== "" &&
      email.hasError === false
    ) {
      dispatch(login(email.value, password))
        .then(() => {
          // Apply language preference upon successful login
          // const preferredLanguage = user.userInfo.languageCode ;
          // i18next.changeLanguage(preferredLanguage);
          // localStorage.setItem("preferredLanguage", preferredLanguage);
        })
        .catch(() => { });
    } else {
    }
  };

  // const info = localStorage.getItem("user");
  // const languageCode = info.userInfo.languageCode ;

  /*******   Hooks  *******/

  useEffect(() => {
    dispatch({
      type: CLEAR_MESSAGE,
    });
    if (userLastVisited !== null) {
      navigate(userLastVisited);
    } else if (
      userState &&
      userState === "true" &&
      user?.userInfo?.role_id === 1
    ) {
      navigate(user?.userInfo?.last_visited_page);

      const preferredLanguage = user?.userInfo?.languageCode;
      //console.log(preferredLanguage, "is prefered language");
      i18next.changeLanguage(preferredLanguage);
      localStorage.setItem("preferredLanguage", preferredLanguage);
    } else if (
      userState &&
      userState === "true" &&
      user?.userInfo?.role_id === 0
    ) {
      navigate("/admin/panel");

      const preferredLanguage = user?.userInfo?.languageCode;
      //console.log(preferredLanguage, "is prefered language");
      i18next.changeLanguage(preferredLanguage);
      localStorage.setItem("preferredLanguage", preferredLanguage);
    } else {
      navigate("/");
    }
  }, [userState]);

  const preferredLanguage = localStorage.getItem("preferredLanguage")

  useEffect(() => {
    const storedLanguage = localStorage.getItem('languageCode');
    if (storedLanguage) {
      setLoginPage(true);
    }
  }, []);

  const openLoginPage = () => {
    if (values?.language !== '' || localStorage.getItem('languageCode')) {
      setLoginPage(true);
    }
    else if (values?.language === "") {
      setEmptyCheck({ languageCode: t('validationRegisterPage:languageCode') });
    }
  };

  const showLanguageSelector = () => {
    setLoginPage(false);
  };

  const lc = localStorage.getItem('languageCode')

  /*******   Render  *******/

  return (
    <div>
      <Helmet>
        <title>{t('login:fields.title')}</title>
      </Helmet>
      <div className="d-flex container align-items-center login-container-wrap">
        <div className="row align-items-center login-col-wrap">
          <div className="col-md-6">
            <div className="text-center">
              <img
                height="auto"
                width="500px"
                src={`${DOMAIN_NAME}images/login/login8464.png`}
                alt="Login Group"
              />
            </div>
          </div>
          <div className="col-md-6">
            {!showLogin && (
              <div className='d-flex justify-content-center align-items-center flex-column mt-5'>
                <div className='d-flex justify-content-center align-items-center'>
                  <label className="m-2 lang" htmlFor="exampleInputPassword1">
                    {t('translation:chooseLanguage')}
                  </label>
                  <Select
                    // placeholder="Choose Your Country"
                    placeholder={
                      <div style={style.placeHolderStyle}>
                        {t('translation:chooseLanguage')}
                      </div>
                    }
                    defaultValue={
                      // preferredLanguage
                      //   ? {
                      //     label: language_list.find((lang) => lang.value === preferredLanguage)?.label || "",
                      //     value: preferredLanguage,
                      //   }
                      //   : 
                      lc
                          ? {
                            label: language_list.find((lang) => lang.value === lc)?.label || "",
                            value: lc,
                          }
                          : null
                    }
                    options={language_list}
                    onChange={languageHandler}
                    className="abc"
                  />
                </div>
                <div>
                  {values?.languageCode === "" && (
                    <small className="text-danger">
                      {emptyCheck?.languageCode}
                    </small>
                  )}
                </div>
                <div className="form-group login-submit " style={{ width: '80%' }}>
                  <button
                    type="button"
                    className="btn blue-style-btns btn-primary btn-sm btn-block"
                    onClick={openLoginPage}
                  >
                    {t('translation:continueRegister')}
                  </button>
                </div>
              </div>

            )}
            {showLogin && (<div><h3 className="text-center pagehead-texttitle">{t('login:fields.pageTitle')}</h3>
              <form onSubmit={handleLogin}>
                <div className="card-body">
                  <div className="form-group forminut-passeye">
                    <label htmlFor="exampleInputEmail1">{t('login:fields.email.label')}</label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder={t('login:fields.email.placeholder')}
                      onChange={changeHandler}
                      onKeyUp={blurHandler}
                    />
                    <div className="passShow-eye">
                      <i className="fa fa-user"></i>
                    </div>
                    <div>
                      {email.value === "" && (
                        <small className="text-danger">
                          {" "}
                          {logineEmptyCheck.email}
                        </small>
                      )}
                    </div>
                    <div>
                      {email.touched && email.hasError && (
                        <small className="text-danger">
                          {t('login:fields.email.errorMessages.invalid')}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="form-group forminut-passeye">
                    <label htmlFor="exampleInputPassword1">{t('login:fields.password.label')}</label>
                    <input
                      type={passwordShown ? "text" : "password"}
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder={t('login:fields.password.placeholder')}
                      onKeyUp={onChangePassword}
                    />
                    {password === "" && (
                      <small className="text-danger">
                        {logineEmptyCheck.password}
                      </small>
                    )}
                    <div
                      className="passShow-eye"
                      onClick={togglePasswordVisiblity}
                    >
                      <i
                        className={
                          passwordShown ? "fa fa-eye-slash" : "fa fa-eye"
                        }
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div className="mt-1">
                      <Link to="/reset-password">{t('login:fields.password.forgotPassword')}</Link>
                    </div>
                  </div>
                  <div className="form-group login-submit ">
                    <div style={{ width: '50%' }}>
                      <button
                        type="button"
                        className="btn blue-style-btns btn-secondary btn-sm btn-block"
                        onClick={showLanguageSelector}
                      >
                        {t("translation:previousPage")}
                      </button>
                    </div>
                    <div style={{ width: '50%' }}>
                      <button
                        type="submit"
                        className="btn blue-style-btns btn-primary btn-sm btn-block"
                      >
                        {t('login:fields.login.button')}
                      </button>
                    </div>
                  </div>
                  <div className="form-group login-sign-up text-center signuptext">
                    <p>
                      {t('login:fields.signup.prompt')}{" "}
                      <Link to="/register">{t('login:fields.signup.link')}</Link>
                    </p>
                  </div>
                </div>
                {message && (
                  <div className="form-group">
                    <div
                      className={
                        message ===
                          t('login:fields.login.successMessage')
                          ? "alert alert-success text-center"
                          : "alert alert-danger text-center"
                      }
                      role="alert"
                    >
                      <div dangerouslySetInnerHTML={{ __html: message }}></div>
                    </div>
                  </div>
                )}
              </form></div>)}

          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
