import React, { useState } from "react";
import AuthService from "../../../services/auth-service";
import Modal from 'react-bootstrap/Modal';
import '../../../styles/login.css';
import { Helmet } from 'react-helmet';
import { DOMAIN_NAME } from "../../../env/Baseurl";
import { useTranslation } from "react-i18next";

const ResetPasswordScreen = () => {
    const {t} =useTranslation(['passwordReset'])

    /*******   States  *******/
    const [email, setEmail] = useState({
        value: "",
        hasError: false,
        touched: false,
    });

    const [logineEmptyCheck, setloginEmptyCheck] = useState({
        email: ""
    });

    // success and error messages
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    //   success and error message modal 
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    let handleClose = () => setShow(false);

    /*******   HelperFunctions  *******/
    const changeHandler = e => {
    
        const inputValue = e.target.value.trim().toLowerCase()
        let hasError = false
        if (
            !/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(
                inputValue
            )
        ) {
            hasError = true
        }
        setEmail(currentValue => ({
            ...currentValue,
            value: e.target.value,
            hasError,
        }))
    }

    const blurHandler = () => {
       
        setEmail(currentValue => ({
            ...currentValue,
            touched: true,
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (email.value === "") {
            setloginEmptyCheck({ email: t('passwordReset:validations.emailRequired') });
        }
        if (email.value !== "" && email.hasError === false) {
            AuthService.resetPassword(email.value).then(res => {
                if (res.status === true) {
                    setSuccessMessage(res.message);
                    handleShow();
                }

                else if(res.status===false)
                {
                    setSuccessMessage(t('passwordReset:messages.emailNotExist'));
                    handleShow();
                }

            }
            ).catch(error => {
                setErrorMessage(t('passwordReset:messages.error'));
                handleShow();

            }
            )
        }
        else {
           
        }
    };

    // close modal on its own

    setTimeout(handleClose, 6000);

    /*******   Render  *******/

    return (
        <div className="d-flex container align-items-center login-container-wrap">
            <Helmet>
                <title>{t('passwordReset:pageTitle')}</title>    
            </Helmet>
            <div className="row align-items-center login-col-wrap">
                <div className="col-md-6">
                    <div className="text-center">
                        <img
                            height="auto"
                            width="500px"
                            src={`${DOMAIN_NAME}images/login/login8464.png`} 
                            alt="Login Group"
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div>
                        <h3 className="text-center pagehead-texttitle">
                        {t('passwordReset:title')}
                        </h3>
                        <form onSubmit={handleSubmit}>
                            <div className="card-body">
                                <div className="form-group forminut-passeye">
                                    <label for="exampleInputEmail1">
                                        {t('passwordReset:fields.email.label')}
                                    </label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="exampleInputEmail1"
                                        placeholder={t('passwordReset:fields.email.placeholder')}
                                        onChange={changeHandler}
                                        onBlur={blurHandler}
                                    />
                                    <div className="passShow-eye"><i className="fa fa-user"></i></div>
                                    
                                    <div>
                                        {email.value === "" && <small className="text-danger"> {logineEmptyCheck.email}</small>}
                                    </div>
                                    <div>
                                        {email.touched && email.hasError && <small className="text-danger">{t('passwordReset:validations.emailInvalid')}</small>}
                                    </div>
                                    <div className="text" style={{ marginTop: '20px' }}>
                                        <p style={{ fontSize: '16px', fontWeight: 'normal' }}>{t('passwordReset:messages.passwordResetInstructions')}</p>
                                    </div>
                                </div>
                                <div className="form-group login-submit">
                                    <button
                                        type="submit"
                                        className="btn blue-style-btns btn-primary btn-sm btn-block">
                                        {t('passwordReset:messages.submitButton')}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                {successMessage ?
                    <Modal.Body style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>{successMessage}</Modal.Body>
                    :
                    <Modal.Body style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>{errorMessage}</Modal.Body>
                }
            </Modal>
        </div>

    );
};

export default ResetPasswordScreen;